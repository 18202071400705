import React from 'react';
import data from '@data/template-5/homepage.json';
import { graphql } from 'gatsby';
import Layout from '@components/commons/template-5/layout';
import Intro from '@containers/template-5/faq/intro';

const IndexPage = ({
    data: {
        AbstractionDataStore: { getWebsite, getUser, getBusiness, getTradePro },
        DomainRequisition: { getDomainRequisitionsByBusinessId: domain },
    },
}) => {
    const appSyncData = {
        trade_pro: {
            email: getTradePro?.emails?.items.length
                ? getTradePro?.emails?.items[0]?.email
                : getUser?.email,
        },
        business: {
            ...getBusiness,
            phone: getBusiness?.phones?.items?.length
                ? getBusiness?.phones?.items[0]?.phoneNumber
                : getUser?.phone,
            email: getBusiness?.emails?.items?.length
                ? getBusiness?.emails?.items[0]?.email
                : getUser?.email,
            displayName: getBusiness?.displayName,
            shortDescription: getBusiness?.shortDescription,
            radiusServed: getBusiness?.serviceArea,
            socialProfiles: getBusiness?.links?.items || [],
            businessFeatures: getWebsite?.highlightedFeatures?.items
                ? getWebsite?.highlightedFeatures?.items
                : [],
            highlightedServices: getWebsite?.highlightedServices?.items
                ? getWebsite?.highlightedServices?.items
                : [],
            feedbacks: getWebsite?.testimonials?.items ? getWebsite?.testimonials?.items : [],
            processes: getBusiness?.processSteps?.items ? getBusiness?.processSteps?.items : [],
        },
        website: {
            ...getWebsite,
        },
    };

    const { business, website } = appSyncData;

    const businessData = {
        ...business,
        address: {
            address: business.location.streetAddress,
            longitude: business.location.longitude,
            latitude: business.location.latitude,
            singleLineAddress: business.location.singleLineAddress,
            state: business.location.state,
        },
    };

    const businessLayoutData = {
        logo: businessData.logoImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
        phone: businessData.phone,
        email: businessData.email,
        singleLineAddress: businessData.address?.singleLineAddress,
        socialProfiles: businessData.socialProfiles,
        services: businessData.highlightedServices,
        ...businessData,
    };

    const seoData = {
        title: businessData.displayName,
        description: businessData.shortDescription,
        domain: domain[0].domainName,
        logo: businessData?.logoImage?.url ?? null,
        previewImage: businessData.coverImage?.url ?? null,
    };

    const faqData = {
        ...data.faq,
        faqs: website.faqs.items,
    };

    return (
        <Layout businessData={businessLayoutData} seoData={seoData} faqs={faqData}>
            <Intro data={faqData} />
        </Layout>
    );
};

export const query = graphql`
    query ($userId: ID!, $businessId: ID!, $websiteId: ID!, $businessIdString: String!) {
        DomainRequisition {
            getDomainRequisitionsByBusinessId(businessId: $businessIdString) {
                id
                domainName
                domainStatus
            }
        }
        AbstractionDataStore {
            getWebsite(id: $websiteId) {
                promotionVisibility
                hideMyLocation
                headline
                subHeadline
                feature
                promotion {
                    description
                    value
                }
                guarantee {
                    caption
                }
                faqs {
                    items {
                        question
                        answer
                    }
                }
                highlightedFeatures {
                    items {
                        text
                        title
                    }
                }
                highlightedServices {
                    items {
                        description
                        name
                    }
                }
                completedProjects {
                    items {
                        description
                        name
                    }
                }
                guaranteeDescriptions {
                    items {
                        title
                        description
                    }
                }
                testimonials {
                    items {
                        description
                        name
                    }
                }
            }
            getUser(id: $userId) {
                email
                phone
            }
            getBusiness(id: $businessId) {
                id
                displayName
                shortDescription
                serviceArea
                location {
                    longitude
                    latitude
                    singleLineAddress
                    state
                    streetAddress
                }
                processSteps {
                    items {
                        stepNumber
                        stepDescription
                        stepTitle
                    }
                }
                ourProcess {
                    caption
                }
                feedbacks {
                    items {
                        review
                        fullName
                    }
                }
            }
        }
    }
`;


export default IndexPage;

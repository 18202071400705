import React, { useEffect, useState } from 'react';
import { Template3FaqItem } from '@tradesorg-components/component-library-waas-templates.template-3-faq-item';
import { nanoid } from 'nanoid';
import './index.scss';

const FaqItemList = ({ perPage, currentPage, items, ...props }) => {
    const [faqState, setFaqState] = useState([]);

    const handleState = (index, value) => {
        const prev = faqState;
        prev[index] = value;
        setFaqState([...prev]);
    };

    useEffect(() => {
        setFaqState([]);
    }, [currentPage]);

    const htmlGenerate = (item, index) => {
        return (
            <div className="w-full border pl-20px pr-10px py-10px md-1:p-0 mt-20px xl:max-w-560px rounded-4px">
                <Template3FaqItem
                    className={{
                        title: 'my-auto title xs:pr-30px sm:pl-0px',
                        content: 'text-gray-500',
                    }}
                    borderTop={false}
                    key={nanoid()}
                    title={item.question}
                    content={
                        <div>
                            <div className="border-t mb-20px xl:mt-6px" />
                            {item.answer}
                        </div>
                    }
                    active={faqState[index]}
                    handleState={handleState}
                    index={index}
                />
            </div>
        );
    };

    const availableItems = () => {
        let maxIndex = currentPage * perPage;
        let minIndex = (currentPage - 1) * perPage;

        return items.filter((item, index) => index >= minIndex && index < maxIndex);
    };

    return (
        <div className={`xl:grid xl:grid-cols-2 gap-4 mx-auto`}>
            <div className="">
                {items.length > 0 &&
                    availableItems().map((item, index) => {
                        if (index % 2 === 0) {
                            return htmlGenerate(item, index);
                        }
                    })}
            </div>
            <div>
                {items.length > 0 &&
                    availableItems().map((item, index) => {
                        if (index % 2 !== 0) {
                            return htmlGenerate(item, index);
                        }
                    })}
            </div>
        </div>
    );
};
export default FaqItemList;

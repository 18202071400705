import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SectionHeadingComponent } from '@tradesorg-components/component-library-waas-templates.template-3-section-heading';
import { InputTemplate } from '@tradesorg-components/component-library-waas-templates.base-input-template';
import FaqItemList from './faq-item-list';
import Paginate from './paginate';
import './index.scss';

const FaqIntro = ({ data }) => {
    const { heading, text, faqs } = data;

    const [search, setSearch] = useState('');
    const [items, setItems] = useState(faqs);
    const [perPage, setPerPage] = useState(10);
    const [pageSize, setPageSize] = useState(Math.ceil(items.length / perPage));
    const [currentPage, setCurrentPage] = useState(1);

    const handleChange = (e) => {
        setSearch(e.target.value);
        handleSearch(e.target.value);
    };
    const handleSearch = (input) => {
        const data = faqs.filter((item) => {
            return item.question.toLowerCase().includes(input.toLowerCase());
        });

        setItems([...data]);
        setPageSize(Math.ceil(data.length / perPage));
    };

    return (
        <div className={`FaqWrapper `}>
            <div className="">
                <div className="justify-center items-center bg-gray-50 py-35px xl:py-94px px-20px mx-auto">
                    <SectionHeadingComponent
                        text={text}
                        heading={ReactHtmlParser(heading)}
                        centered={true}
                        className={{
                            heading: 'xs:font-24px md-1:font-30px text-center mb-18px xl:mb-23px',
                            text: 'xs:font-14px md-1:font-16px opacity-50 w-334px sm:w-full lg:w-560px leading-loose',
                            textWrapper: 'xs:max-w-334px sm:max-w-full lg:max-w-560px md-1:w-full',
                        }}
                    />
                </div>
                <div className="mx-auto" style={{ maxWidth: '1920px' }}>
                    <div className="md-1:px-21px xs:max-w-334px sm:max-w-full md-1:max-w-560px xsw-full mx-auto xl:mt-70px xl:mb-50px mt-50px mb-22px">
                        <InputTemplate
                            autoComplete={'off'}
                            classNames={{
                                wrapper: 'faq-search sm:px-10px xs:mb-10px sm:mb-20px',
                                input: 'xs:px-0 sm:px-10px px-0',
                                rightComponent: 'xs:pr-0',
                            }}
                            rounded={true}
                            name="search"
                            id="search"
                            placeholder="Enter your question..."
                            onChange={handleChange}
                            value={search}
                            RightComponent={
                                <div className="bg-faq-item relative bg-faq-item-template-5 cursor-pointer ml-auto">
                                    <svg
                                        className="faq-item-template-5-heading-icon"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 20 20"
                                        fill="#3287eb"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55141 0 0 3.55145 0 7.9167C0 12.2819 3.55145 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47024 14.1667 1.66668 11.3631 1.66668 7.9167C1.66668 4.47024 4.47024 1.66668 7.9167 1.66668C11.3631 1.66668 14.1667 4.47024 14.1667 7.9167C14.1667 11.3631 11.3631 14.1667 7.9167 14.1667Z"
                                            fill="#3287eb"
                                        />
                                    </svg>
                                </div>
                            }
                        />
                    </div>

                    <div className="px-20px xl:px-98px 3xl:pl-390px 3xl:pr-390px">
                        <FaqItemList items={items} perPage={perPage} currentPage={currentPage} />
                    </div>

                    <Paginate
                        items={items}
                        perPage={perPage}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default FaqIntro;
